import React, { useContext, useEffect } from "react";

import Box from "@mui/material/Box";

import { useParams } from "react-router-dom";

import { useTheme } from "@emotion/react";
import { SidebarContext } from "../../../../../contexts/SidebarContext";
import LectureSidebar from "./LectureSidebar";
import Lecture from "./Lecture";
import { SidebarCourseProvider } from "../../../../../contexts/SidebarCourseContext";
import LoadingSpinner from "../../../../../components/LoadSpinner";
import CourseContext from "../../../../../contexts/CourseContext";
import CssBaseline from '@mui/material/CssBaseline';

export default function LectureContainer({key}) {
  const { courseId, moduleId, contentId } = useParams();
  const theme = useTheme();
  const { sidebarToggle, closeSidebar, toggleSidebar } =
    useContext(SidebarContext);
  
  const {course,setCourseValues,handleLoad} = useContext(CourseContext)

  useEffect(()=>{
    //console.log("courseId",courseId, "moduleId",moduleId, "contentId",contentId)
    setCourseValues(courseId,moduleId,contentId)
    handleLoad();
  },[courseId,moduleId,contentId,key])

  useEffect(() => {
    console.log("cLICK rECEIVED")  
  }, []);

  useEffect(() => {
    closeSidebar();
  }, [closeSidebar]);

  return (
    course ? 
    <SidebarCourseProvider>
      <Box sx={{ display: "flex" }}>
        <CssBaseline/>
        <Lecture courseId={courseId} />
        <LectureSidebar course={course}/>
      </Box>
    </SidebarCourseProvider>
   : <LoadingSpinner/>
  );
}
