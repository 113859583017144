import { Link, Typography } from "@mui/material";
import { Container } from "@mui/system";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { getDownload } from "../../../../../api/lib/course";
import useRefMounted from "../../../../../hooks/useRefMounted";
import { useCallback } from "react";

const LectureFile = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isMountedRef = useRefMounted();

  const downloadFile = (filename, fileUrl) => {
    fetch(fileUrl)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      })
      .catch(error => {
        //console.error('Error downloading file:', error);
        window.open(fileUrl, '_blank');
      });
  };

  const getDownloadF = useCallback(
    async (courseId,moduleId,lectureId) => {
      try {
        const response = await getDownload(courseId,moduleId,lectureId);

        if (isMountedRef.current) {
          
          const fileUrl = response.data.url
          
          downloadFile(response.data.title, fileUrl);
          
        }
      } catch (err) {
         console.log("ERROR GET COURSE Files", err);
      }
    },
    [isMountedRef]
  );

  // Function will execute on click of button
  const onButtonClick = (event) => {
    event.preventDefault();

    getDownloadF(props.courseId, props.moduleId, props.contentId);

  };

  return (
    <Container>
      <Typography variant="h3" component="h4" align="center">
        {t("Your download is ready!")}
      </Typography>
      <br></br>
      <Typography variant="h4" component="h5" align="center">
        {props.lecture.title}
      </Typography>

      <Typography align="center">
        <Link href="#" color="inherit" onClick={onButtonClick}>
          <CloudDownloadIcon fontSize="large" />
        </Link>
      </Typography>
    </Container>
  );
};

export default LectureFile;
