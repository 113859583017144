import axiosClient from "../apiClient";

export async function login(email, password) {
  return axiosClient
    .post("/auth/login/", { email, password })
    .then((response) => {
      // console.log("LOGIN REMOVE SESSION");
      localStorage.removeItem("session");
      if (response.data.data.token) {
        localStorage.setItem(
          "session",
          JSON.stringify(response.data.data.token)
        );
      }
      return response;
    });
}

export async function logout() {
  const session = JSON.parse(localStorage.getItem("session"));
  const token = session.key;
  return axiosClient.post("/auth/logout/", { token }).then((response) => {
    // console.log("LOGOUT REMOVE SESSION");
    localStorage.removeItem("session");
    // console.log("Logout Response", response);
  });
}

export async function register(username, email,birth_date, password,legal_terms) {
  //verify if exist utm params
  const utm_source = localStorage.getItem("utm_source");
  const utm_medium = localStorage.getItem("utm_medium");
  const utm_campaign = localStorage.getItem("utm_campaign");


  //send utm params to backend as json
  let utm_params = {
      utm_source: utm_source,
      utm_medium: utm_medium,
      utm_campaign: utm_campaign,
    };
 



  return axiosClient.post("/auth/register/", { username, email,birth_date, password,utm:utm_params,legal_terms });
}

export async function passwordReset(email) {
  return axiosClient.post("/auth/request-password-reset/", { email });
}

export async function validate_password_token(token) {
  return axiosClient.put(`/auth/validate-password-token/${token}/`);
}

export async function change_password_token(token, newpassword1, newpassword2) {
  return axiosClient.put(`/auth/change-password-token/${token}/`, {
    newpassword1,
    newpassword2,
  });
}

export async function update_avatar(uuid, data) {
  // console.log("FORM", data);
  return axiosClient.put(`/auth/user-avatar/${uuid}/`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}

export async function update_cover(uuid, data) {
  return axiosClient.put(`/auth/user-cover/${uuid}/`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}

export async function validate_email(token) {
  const session = JSON.parse(localStorage.getItem("session"));

  return axiosClient.put(`/auth/validate-email-token/${token}/`);
}

export async function request_email_token(token) {
  const session = JSON.parse(localStorage.getItem("session"));
  return axiosClient.post(`/auth/request-email-token/${token}/`);
}

export async function request_email_token_auth(email, password) {
  //const session = JSON.parse(localStorage.getItem("session"));
  return axiosClient.post(`/auth/request-email-token/`, { email, password });
}

export async function change_password(
  uuid,
  oldpassword,
  newpassword1,
  newpassword2
) {
  return axiosClient.put(`/auth/change-password/${uuid}/`, {
    oldpassword,
    newpassword1,
    newpassword2,
  });
}

export async function change_theme(params) {
  return axiosClient.post(`/auth/user_pref/${params.uuid}/`, params);
}

export async function getUserProfile(uuid='current') {
  return await axiosClient.get(`/auth/user-profile/${uuid}/`);
}

export async function getMyLearning() {
  return axiosClient.get(`/auth/mylearning/`);
}

export async function getDashboard() {
  return axiosClient.get(`/auth/dashboard/`);
}

export async function getBadge(chall_uuid) {
  return axiosClient.get(`/auth/badge/${chall_uuid}/`);
}

export async function getAchiviement(achiviement_uuid) {
  return axiosClient.get(`/api/achiviement/${achiviement_uuid}/`);
}

export async function getUserRanking(uuid) {
  return axiosClient.get(`/auth/user/ranking/${uuid}/`);
}


export async function getScoreBoard() {
  return axiosClient.get(`/auth/scoreboard/`);
}

export async function getbanners() {
  return axiosClient.get(`/auth/banners/`);

}

export async function updateUserProfile(
  uuid,
  nome_completo,
  username,
  bio,
  twitter_username,
  github_username,
  linkedin_username,
  personal_website
) {
  return axiosClient.put(`/auth/user-profile/${uuid}/`, {
    nome_completo,
    username,
    bio,
    twitter_username,
    github_username,
    linkedin_username,
    personal_website,
  });
}

//Get logs from user

export async function getUserLogs() {
  return axiosClient.get(`/api/act_logs/`);
}

export async function getVPNServers() {
  return axiosClient.get(`/auth/vpn_server/`);
}

export async function getVPNProfile(uuid) {
  return axiosClient.post(`/auth/user_vpn_profile/${uuid}/`, {
    responseType: "blob",
  });
}



export async function create_ticket(subject,description,category) {
 
  return axiosClient.post("/auth/ticket/", {subject,description,category});

}

export async function getUserTimeline(uuid,env) {
  return await axiosClient.get(`/auth/usertimeline/${uuid}/?env=${env}`);
}