import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardContent,
  CardHeader,
  Paper,
  useTheme,
  Grid,
  Tooltip,
} from "@mui/material";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";

import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate, useParams } from "react-router-dom";
import { Toolbar, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import ArticleIcon from "@mui/icons-material/Article";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import QuizIcon from "@mui/icons-material/Quiz";
import { getCourseDetail } from "../../../../../api/lib/course";
import LoadingSpinner from "../../../../../components/LoadSpinner";
import useRefMounted from "../../../../../hooks/useRefMounted";
import { styled } from "@mui/material/styles";
import { alpha, Box } from "@mui/system";
import { SidebarCourseContext } from "../../../../../contexts/SidebarCourseContext";
import CourseContext from "../../../../../contexts/CourseContext";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import ExtremeIcon from "../../../../../components/ExtremeIcon";

const drawerWidth = 320;

const ListItemTextMenu = styled(ListItemText)`
  font-size-adjust: 0.5;
  font-size: 1.5em;
  font-weight: 100;
`;

const useStyles = makeStyles((theme) => ({
  
  cardElement: {
    
    marginBottom: theme.spacing(1),
    border: "none",
    boxShadow: "none"

  },
  cardItem: {
    
    backgroundColor: theme.palette.primary.main + "33",
    padding: '0px',
    height: "60px",
    width: "100%",
    transition: "0.3s",
    fontSize: "16px",
    "&:hover": {
      backgroundColor: theme.palette.primary.main + "80",
      transform: "scale(1.03)",
      cursor: "pointer",
    },
  },
  title: {
    fontSize: "20px",
    fontWeight: "bold",
    marginBottom: "10px",
  },
}));

const LectureSidebar = ({ course }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const classes = useStyles();

  const { sidebarToggle, closeSidebar, toggleSidebar } =
    useContext(SidebarCourseContext);
  const { setCourseValues, handleLoad } = useContext(CourseContext);

  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(true);

  const isMountedRef = useRefMounted();

  const navigate = useNavigate();

  const handleDrawerLeftClose = () => {
    toggleSidebar();
  };

  const handleLectureClick = (courseId, moduleId, contentId) => {
    setCourseValues(courseId, moduleId, contentId);
    handleLoad();
  };

  return course ? (
    <>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
          },
        }}
        variant="persistent"
        anchor="right"
        open={sidebarToggle}
      >
        <Box sx={{ position: "relative", pt: `${theme.header.height}` }}>
          <IconButton
            onClick={handleDrawerLeftClose}
            sx={{ background: `${theme.colors.primary.main}`, fontSize: 36 }}
          >
            <>
              {theme.direction === "ltr" ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </>
          </IconButton>
        </Box>
        <Box>
          
          <Typography align="center" variant="h4" >
              {course.title}
          </Typography>
          
          {course.modules.map((module, index) => (
            <Card key={index} square={true}>
              <CardContent >
                <Typography className={classes.title}>
                  {module.title}
                </Typography>
              </CardContent>
              {module.ct.map((content, index) => (
                <Card
                  key={index}
                  square={true}
                  className={classes.cardElement}
                  onClick={() =>
                    handleLectureClick(course.id, module.id, content.id)
                  }
                >
                  <CardContent
                    className={classes.cardItem}
                  >
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start"
                    >
                      <Grid item>
                        {content.type === "video" && <OndemandVideoIcon />}
                        {content.type === "text" && <ArticleIcon />}
                        {content.type === "file" && <AttachFileIcon />}
                        {/* {content.type === "quiz" && <QuizIcon />} */}
                        {content.type === "quiz" && <ExtremeIcon />}
                      </Grid>
                      <Grid item xs={9}>
                        <Tooltip title={content.title}>
                          <Typography
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              maxWidth: "200px",
                              minWidth: "200px",
                            }}
                            align="left"
                          >
                            {content.title}
                          </Typography>
                        </Tooltip>
                        
                      </Grid>
                      <Grid item>
                        <IconButton
                          aria-label="comments"
                          sx={{
                            padding: "0px",
                          }}
                        >
                          
                          <Checkbox
                            inputProps={{ "aria-label": "controlled" }}
                            checked={content.is_complete}
                            tabIndex={-1}
                            disableRipple
                            sx={{
                              padding: "0px",
                            }}
                          ></Checkbox>
                        </IconButton>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              ))}
            </Card>
          ))}
        </Box>
      </Drawer>
      {!sidebarToggle && (
        <Box
          display="flex"
          sx={{
            position: "fixed",
            top: `${theme.header.height}`,
            right: 0,
            height: "100%",
            backgroundColor: `${alpha(theme.header.background, 0.95)}`,
            backdropFilter: `blur(3px)`,
            boxSizing: "border-box",
            width: "40px",
          }}
          onClick={handleDrawerLeftClose}
        >
          <IconButton
            onClick={handleDrawerLeftClose}
            sx={{
              position: "inherit",
              background: `${theme.colors.primary.main}`,
              fontSize: 36,
            }}
          >
            <>
              {theme.direction === "ltr" ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </>
          </IconButton>
        </Box>
      )}
    </>
  ) : (
    <LoadingSpinner />
  );
};

export default LectureSidebar;
