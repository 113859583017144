import React, { useCallback, useEffect, useState } from "react";
import { Button, Grid, Snackbar, TextField, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import SendIcon from "@mui/icons-material/Send";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  getQuestionHint,
  sendSimpleAsnswer,
} from "../../../../../../api/lib/course";
import { useTranslation } from "react-i18next";
import MuiAlert from "@mui/material/Alert";
import { LoadingButton } from "@mui/lab";
import { use } from "i18next";
import useRefMounted from "../../../../../../hooks/useRefMounted";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const SimpleAnswer = (props) => {
  const [open, setOpen] = useState(false);
  const [answer, setAnswer] = useState(props.answer);
  const [placeholder, setPlaceholder] = useState(props.answer);
  const [loading, setLoading] = useState(false);
  const [erro, setErro] = useState(null);
  const [disabled, setDisabled] = useState(props.is_solved);
  const [inputDisabled, setInputDisabled] = useState(
    props.type === "C" ? true : false
  );
  const { t } = useTranslation();
  const isMountedRef = useRefMounted();
  const [retrieveHint, setRetrieveHint] = useState(false);

  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [openHint, setOpenHint] = useState(false);
  const [percent, setPercent] = useState(-1);
  const [hint, setHint] = useState("");

  const [snackPosition, setSnackPosition] = useState({
    vertical: "bottom",
    horizontal: "center",
  });

  const { vertical, horizontal } = snackPosition;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function handleChange(event) {
    setAnswer(event.target.value);
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);
    setErro(null);
    // console.log("Submit event", props);
    try {
      let data = { ans: answer };

      let response = await sendSimpleAsnswer(props.quiz_id, props.id, data);

      if (response.data.status == "correct") {
        setOpenSuccess(true);
        setDisabled(true);
      } else {
        let percent = response.data.percent;
        if (percent !== -1)
        {
          setPercent(response.data.percent);
        }
        else{
          setPercent(0);
        }
        
        setOpenError(true);
        setAnswer("");
      }
    } catch (erro) {
      setOpenError(true);
    } finally {
      setLoading(false);
    }
  }

  const handleCloseSnack = (event) => {
    setOpenError(false);
    setOpenSuccess(false);
  };

  const getHint = useCallback(async () => {
    try {
      const response = await getQuestionHint(props.quiz_id, props.id);

      if (isMountedRef.current) {
        setHint(response.data.hint);
      }
    } catch (err) {
      // console.log("ERROR GET HINT", err);
    }
  }, [hint, isMountedRef]);

  const handleOpenHint = () => {
    setOpenHint(true);
  };

  const handleCloseHint = () => {
    setOpenHint(false);
  };

  const handleGetHint = () => {
    handleClose();
    setRetrieveHint(true);
  };

  useEffect(() => {
    if (retrieveHint) {
      getHint();
      setOpenHint(true);
      setRetrieveHint(false);
    }
  }, [retrieveHint, getHint]);

  return (
    <form onSubmit={handleSubmit}>
      <Snackbar
        open={openSuccess}
        autoHideDuration={2000}
        onClose={handleCloseSnack}
        anchorOrigin={{ vertical, horizontal }}
        key={"success"}
        sx={{ zIndex: 10000 }}
      >
        <Alert
          severity="success"
          sx={{ width: "100%" }}
          onClose={handleCloseSnack}
        >
          {t("Congratulations! Correct answer")} ✅
        </Alert>
      </Snackbar>
      <Snackbar
        open={openError}
        autoHideDuration={2000}
        onClose={handleCloseSnack}
        key={"wrong"}
        anchorOrigin={{ vertical, horizontal }}
        sx={{ zIndex: 10000 }}
      >
        <Alert
          severity="error"
          sx={{ width: "100%" }}
          onClose={handleCloseSnack}
        >
          {t("Opss! this is not the correct answer.") + t("Percentual de acerto: ") + percent + "%" } ❌!
        </Alert>
      </Snackbar>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("The tips come at a cost.")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("This tip will cost") + " " + props.hint_cost + t("pontos")}.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t("I don't want the tip")}</Button>
          <Button onClick={handleGetHint} autoFocus>
            {t("I Accept")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openHint}
        onClose={handleCloseHint}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <TipsAndUpdatesIcon />
          {t("Enjoy your tip")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {hint}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseHint}>{t("Thanks")}</Button>
        </DialogActions>
      </Dialog>

      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
      >
        <Grid item xs={8}>
          {/* {console.log("disabled QUESTION", disabled)} */}
          {disabled && (
            <TextField
              sx={{
                fontSize: "1.75em",
                lineHeight: "calc(1ex / 0.38)",
                margin: "calc(1ex / 0.38) 0",
              }}
              fullWidth
              id={props.id}
              value={answer}
              label={t("Answer")}
              onChange={handleChange}
              disabled={disabled}
            />
          )}
          {!disabled && (
            <TextField
              sx={{
                fontSize: "1.75em",
                lineHeight: "calc(1ex / 0.38)",
                margin: "calc(1ex / 0.38) 0",
              }}
              fullWidth
              id={props.id}
              placeholder={
                props.type === "C"
                  ? t("Execute the task and click Complete")
                  : placeholder
              }
              value={answer}
              autoFocus
              onChange={handleChange}
              onClick={() => {
                placeholder === answer && setAnswer("");
              }}
            />
          )}
        </Grid>

        <Grid item xs={props.has_hint ? 2 : 4}>
          <LoadingButton
            loading={loading}
            fullWidth
            type="submit"
            variant="contained"
            endIcon={<SendIcon />}
            disabled={disabled}
          >
            {props.type == "C" ? t("Complete") : t("Send")}
          </LoadingButton>
        </Grid>

        <Grid item xs={2}>
          {!disabled && props.has_hint && (
            <Button
              fullWidth
              variant="contained"
              endIcon={<TipsAndUpdatesIcon />}
              onClick={handleClickOpen}
            >
              {t("Hint")}
            </Button>
          )}
        </Grid>
      </Grid>
    </form>
  );
};

export default SimpleAnswer;
